import React from 'react';
import { Link } from 'react-router-dom';
import { CardHeader, Input, Label } from 'reactstrap';

const PreviewCardHeader = ({ title, value }) => {
    return (
        <React.Fragment>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
                <div className="flex-shrink-0">
                    {value &&
                        <div className="form-check form-switch form-switch-right form-switch-md">
                            {/* <Label className="form-label text-muted">Show Code</Label> */}
                            {/* <Input className="form-check-input code-switcher" type="checkbox" /> */}
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-primary" type="button"  ><Link to="/edit-code-list" className="nav-link">Edit</Link></button>
                            </div>
                        </div>}
                </div>
            </CardHeader>
        </React.Fragment>
    );
}

export default PreviewCardHeader;