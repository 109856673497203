import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { searchBooleanLogicSuccess,searchBooleanLogicFailure  } from "./action";
import { CodeBuilderActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";

// Get All the budget by project id
console.log(baseAPI,'baseAPI')
function* workerGetAllInputBooleanDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.INPUT_BOOLEAN_LOGIC_SEARCH;
    const { data } = yield call(baseAPI.post, `${uri}`, request);
    console.log(data, "acasdcadvadsvcavadv");
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(searchBooleanLogicSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.error(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(searchBooleanLogicFailure(data))
    }
  } catch (error) {
    yield put(searchBooleanLogicFailure(error))
  }
}

function* CodeBuilderSaga() {
  yield takeEvery(CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST, workerGetAllInputBooleanDetails);
}

export default CodeBuilderSaga;