import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/DashboardCrm";
//pages
import Starter from '../pages/Pages/Starter/Starter';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import CodeList from "../pages/CodeList/CodeList";
import EditCodeList from "../pages/CodeList/EditCodeList";
import CodeRuleGenerator from "../pages/CodeBuilder/CodeRuleGenerator";
import CodeConfiguration from "../pages/CodeConfiguration/CodeConfiguration";
import VehicleTypes from "../pages/Masters/VehicleType";
import CountryGroup from "../pages/Masters/CountryGroup";
import AddCountry from "../pages/Masters/AddCountry";
import AddOptions from "../pages/Masters/AddOptions";
import VehicleGroups from "../pages/Masters/VehicleGroup";

const authProtectedRoutes = [
  { path: "/code-list", component: <CodeList /> },
  { path: "/code-rule-generator", component: <CodeRuleGenerator /> },
  { path: "/", component: <CodeList /> },
  { path: "/index", component: <DashboardCrm /> },
  { path: "/val", component: <Maintenance /> },
  { path: "/edit-code-list", component: <EditCodeList /> },
  { path: "/code-configuration", component: <CodeConfiguration /> },
  { path: "/vehicle-type", component: <VehicleTypes /> },
  { path: "/country-group", component: <CountryGroup/> },
  { path: "/add-country", component: <AddCountry/> },
  { path: "/add-options", component: <AddOptions/> },
  { path: "/vehicle-group", component: <VehicleGroups/> },

  //Pages
  { path: "/pages-starter", component: <Starter /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/", 
  //   exact: true,
  //   component: <Navigate to="/dashboard" />,
  // },
  // { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages

  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

];

export { authProtectedRoutes, publicRoutes };