import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import PreviewCardHeader from '../../Components/Common/PreviewCardHeader';
// import { StripedColumnsTables } from './BasicTablesCode';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';



const EditCodeList = () => {
    document.title = "CRM | EVT-Code List";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <PreviewCardHeader title="Edit Code Lists" value ={false} />
                                <CardBody>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">Code Group</th>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Code Description</th>
                                                        <th scope="col">Remarks</th>
                                                        <th scope="col" colSpan="3">C400.201-1823 R / 4x2 WB 4200</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col">Middle East</th>
                                                        <th scope="col">Algeria</th>
                                                        <th scope="col">Vietnam</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><Link to="#" className="fw-medium">Instrument cluster</Link></td>
                                                        <td>EG9</td>
                                                        <td>Music System (Radio with USB connection)</td>
                                                        <td>Regularization : Code regularized as S F04 inline with release</td>
                                                        <td>SE 3</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Link to="#" className="fw-medium">Instrument cluster</Link></td>
                                                        <td>UJ3</td>
                                                        <td>Music system provision</td>
                                                        <td>Regularization : GCC Rigid</td>
                                                        <td>SE 3</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Link to="#" className="fw-medium">Seats</Link></td>
                                                        <td>SM3</td>
                                                        <td>1 hinged berth at the bottom, with storage under berth</td>
                                                        <td>Regularization : Code regularized as S F04 inline with release</td>
                                                        <td>SE 3</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Link to="#" className="fw-medium">Fuel Tank / Exhaust</Link></td>
                                                        <td>VX6</td>
                                                        <td>Shorter Tail pipe</td>
                                                        <td>Regularization : GCC Rigid models - Shorter tail pipe required only for Euro 3. Code regularization done </td>
                                                        <td>SE 3</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                                {/* <Striped /> */}
                                            </code>
                                        </pre>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-primary" type="button" ><Link to="/code-list" className="nav-link">Submit</Link></button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default EditCodeList;