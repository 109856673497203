import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { searchBooleanLogicAction } from '../../store/actions';
import {
    Input, Button, ButtonGroup, UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, CardBody,
    Col, Container, Row, CardHeader, Label, Card, UncontrolledDropdown
} from 'reactstrap';
import SimpleBar from "simplebar-react";
import Loader from '../Loader/Loader';


const CodeRuleGenerator = (props) => {
    document.title = "Code Rule Generator";

    const { inputBooleanResponse, loading } = props;
    const [inputDecoder, setInputDecoder] = useState(null);
    const [formValidation, setFormValiodation] = useState({});
    // const [loading, setLoading] = useState(false);
    const [booleanLogicCodeCombination, setBooleanLogicCodeCombination] = useState(null);
    console.log(booleanLogicCodeCombination, 'booleanLogicCodeCombination');
    const errors = {}

    const handleDecoder = () => {
        const stateDecode = inputDecoder || ""
        const deCodeValue = stateDecode.trim();
        if (!deCodeValue) {
            errors.deCoder = 'Please fill the field'
        }
        setFormValiodation(errors);
        if (deCodeValue) {
            const requestData = {
                booleanLogic: inputDecoder
            }
            props.searchBooleanLogicAction(requestData)
        }
    }

    // useEffect(() => {
    //     props.searchBooleanLogicAction("-(IC2/IC3/IV0/IY8)+-(U02/WI6)/(XP3+MS3+-(U02/WI6))")
    // }, [])

    useEffect(() => {
        setBooleanLogicCodeCombination(inputBooleanResponse)
    }, [inputBooleanResponse])


    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code Rule Generator" pageTitle="Code Builder" />
                    <Row>
                        <Col xl={2}></Col>
                        <Col xl={8}>
                            <Card>
                                <CardHeader className="align-items-center d-flex p-2">
                                    <h4 className="card-title mb-0 flex-grow-1">Input Boolean Logic</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="live-preview p-2">
                                        <div className="table-responsive table-card">
                                            <Row className="align-items-center g-3 w-100">
                                                <div className="input-group">
                                                    <Input type="text" className="form-control" aria-label="Dollar amount (with dot and two decimal places)" onChange={({ target }) => setInputDecoder(target.value)} />
                                                    <span><Button className="input-group-text btn btn-primary btn-custom-blue" onClick={handleDecoder} type="submit" color='light'>Decoder</Button></span>
                                                </div>
                                                <span className='text-validation'>{formValidation.deCoder}</span>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={2}></Col>
                      { booleanLogicCodeCombination && 
                        <Col xl={12}>
                        <Card>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">Boolean Logic Combination with description</h4>
                            </CardHeader>
                            <CardBody className='card-padding'>
                                <div >
                                    <SimpleBar
                                        className="pe-2 me-n1"
                                        // style={{ height: "400px" }}
                                        style={{ minHeight: '100px' }}
                                    >
                                        {
                                            booleanLogicCodeCombination?.booleanLogicCodeCombination?.map((item, index) => {
                                                return (
                                                    <Card key={index} className='mb-1'>
                                                        <CardBody className='p-2'>
                                                            <div className='flex-grow-1 ms-3 shadow-none'>
                                                                <h6 className='fs-15'>{item.codeCombination}</h6>
                                                                <p className='text-muted mb-0'>{item.codeCombinationDescription}</p>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            })
                                        }
                                    </SimpleBar>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        </div>
                        <Card>
                            <CardHeader className="align-items-center d-flex p-2">
                                <h4 className="card-title mb-0 flex-grow-1">Truth Table</h4>
                                <div className="flex-shrink-0">
                                    <div className="form-check form-switch form-switch-right form-switch-md">
                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className='card-padding'>
                                <div className="live-preview">
                                    <div className="table-responsive table-card">
                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                            <thead className="table-light">
                                                <tr >
                                                    {
                                                        booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableHeaders?.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <th scope="col">{item}</th>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <th scope="col">Explanation</th>
                                                    <th scope="col" style={{ width: '10px' }}>Required</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {booleanLogicCodeCombination?.booleanLogicTruthTable?.truthTableRows?.map((item, index) => (
                                                   
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            {item.values.map((val, i) => (
                                                                <td key={i}>{val}</td>
                                                            ))}
                                                            <td className="center-content">
                                                                <Input
                                                                value={item.explaination}
                                                                    id={`input-${index}`}
                                                                    name={`input-${index}`}
                                                                    className="form-control"
                                                                    type="text"
                                                                    required
                                                                    autoComplete="off"
                                                                />
                                                            </td>
                                                            <td className="center-content">
                                                                <div className="form-check form-switch form-switch-right form-switch-md">
                                                                <Input className="form-check-input code-switcher" type="checkbox" checked={item.isRequired}/>
                                                            </div>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' >Sancheck</Button>
                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light'>Encode Logic</Button>
                            </div>
                        </Card>

                    </Col>
                    }
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = (state) => ({
    inputBooleanResponse: state.CodeBuilderReducer.inputBooleanResponse,
    loading: state.CodeBuilderReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
    searchBooleanLogicAction: (data) => {
        dispatch(searchBooleanLogicAction(data))
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(CodeRuleGenerator);
