import { CodeBuilderActionTypes } from "./actionType";


const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    inputBooleanResponse: {},

}

const CodeBuilderReducer = (state = initialState, action) => {
    const response = action.data;
    console.log(response, "responsessssssss");
    switch (action.type) {
        case CodeBuilderActionTypes.INIT_GET_BUDGET_DETAILS_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], inputBooleanResponse: {},
            }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST:
            return { ...state, loading: true, inputBooleanResponse: {} }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_SUCCESS:
            console.log(response,'red')
            return { ...state, loading: false, inputBooleanResponse: response.data }
        case CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_FAILED:
            return { ...state, loading: false, errors: action.error }
        default: {
            return state;
        }
    }
}

export default CodeBuilderReducer;