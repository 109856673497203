// Import the action types
import { CodeBuilderActionTypes } from "./actionType";

// Define the action creators
export const searchBooleanLogicAction = (data) => (
    {
    type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_REQUEST,
    data
});

export const searchBooleanLogicSuccess = (data) => {
    console.log('Dispatching success action with data:', data);
    return {
        type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_SUCCESS,
        data
    };
};

export const searchBooleanLogicFailure = (error) => ({
    type: CodeBuilderActionTypes.UPDATE_INPUT_BOOLEAN_FAILED,
    error
});


