import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from '../../Components/Common/PreviewCardHeader';
// import { StripedColumnsTables } from './BasicTablesCode';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';



const CodeList = () => {
    document.title = "CRM | EVT-Code List";

    const [selectedSingle, setSelectedSingle] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroup2, setSelectedGroup2] = useState(null);
    const [selectedNoSortingGroup, setSelectedNoSortingGroup] = useState(null);
    const [selectedMulti, setselectedMulti] = useState(null);
    const [selectedMulti2, setselectedMulti2] = useState(null);
    const [selectedMulti3, setselectedMulti3] = useState(null);
    const [showTableList, setShowTableList] = useState(false);
    const [editTableLists, setEditTableList] = useState(true);
    const [codeListLable, setCodeListLable] = useState("View");
    const animatedComponents = makeAnimated();

    const SingleOptions = [
        { value: 'Nepal', label: 'Nepal' },
        { value: 'HDT BSVI', label: 'HDT BSVI' },
    ];

    const CategoryOptions = [
        { value: 'HDT Exports', label: 'HDT Exports' },
        { value: 'Middle east', label: 'Middle east' },
    ];

    const GroupOptions = [

        {
            label: "UK",
            options: [
                { label: "seat", value: "seat" },
                { label: "Engine", value: "Engine" }
            ]
        }
    ];

    const ModelOption = [
        {
            label: "UK",
            options: [
                { label: "C400201", value: "C400201" },
                { label: "C400812", value: "C400812" }
            ]
        }

    ]

    const GroupOptions2 = [
        { value: 'F04', label: 'F04' },
        { value: 'MW2', label: 'MW2' }
    ];

    const ModelNameOptions = [
        { value: '18 T R', label: '18 T R' },
        { value: '28T R', label: '28T R' }
    ];

    const noSortingGroup = [
        { value: 'Madrid', label: 'Madrid' },
        { value: 'Toronto', label: 'Toronto' },
        { value: 'Vancouver', label: 'Vancouver' },
        { value: 'London', label: 'London' },
        { value: 'Manchester', label: 'Manchester' },
        { value: 'Liverpool', label: 'Liverpool' },
        { value: 'Paris', label: 'Paris' },
        { value: 'Malaga', label: 'Malaga' },
        { value: 'Washington', label: 'Washington' },
        { value: 'Lyon', label: 'Lyon' },
        { value: 'Marseille', label: 'Marseille' },
        { value: 'Hamburg', label: 'Hamburg' },
        { value: 'Munich', label: 'Munich' },
        { value: 'Barcelona', label: 'Barcelona' },
        { value: 'Berlin', label: 'Berlin' },
        { value: 'Montreal', label: 'Montreal' },
        { value: 'New York', label: 'New York' },
        { value: 'Michigan', label: 'Michigan' },
    ];

    const options = [
        { label: "josh@joshuajohnson.co.uk", value: 1, disabled: true },
        { label: "joe@bloggs.co.uk", value: 2, disabled: true }
    ];

    function handleSelectSingle(selectedSingle) {
        setSelectedSingle(selectedSingle);
    }

    function handleSelectGroups(selectedGroup) {
        setSelectedGroup(selectedGroup);
    }

    function handleSelectGroups2(selectedGroup2) {
        setSelectedGroup2(selectedGroup2);
    }

    function handleSelectNoSortingGroup(selectedNoSortingGroup) {
        setSelectedNoSortingGroup(selectedNoSortingGroup);
    }

    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
    }

    function handleMulti2(selectedMulti2) {
        setselectedMulti2(selectedMulti2);
    }

    function handleMulti3(selectedMulti3) {
        setselectedMulti3(selectedMulti3);
    }

    const handleCodeListEdit = () => {
        setEditTableList(false);
        setCodeListLable("Edit")
    }

    const handleCodeListSubmit = () => {
        setEditTableList(true);
        setCodeListLable("View")
    }
 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Code List" pageTitle="Code List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='card-bg'><h4 className="card-title mb-0 ">List Search</h4> </CardHeader>
                               
                                <CardBody>
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-default" className="form-label text-muted">Category</Label>
                                                        <Select
                                                            value={selectedSingle}
                                                            onChange={() => {
                                                                handleSelectSingle();
                                                            }}
                                                            options={CategoryOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-groups" className="form-label text-muted">Model</Label>
                                                        <Select
                                                            value={selectedGroup}
                                                            // searchable
                                                            onChange={() => {
                                                                handleSelectGroups();
                                                            }}
                                                            options={ModelOption}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-no-search" className="form-label text-muted">Model Name</Label>
                                                        <Select
                                                            isClearable={true}
                                                            value={selectedGroup2}
                                                            onChange={() => {
                                                                handleSelectGroups2();
                                                            }}
                                                            options={ModelNameOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                {/* <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-no-sorting" className="form-label text-muted">Options added
                                                            via config with no sorting</Label>                                                        
                                                        <Select
                                                            value={selectedNoSortingGroup}
                                                            onChange={() => {
                                                                handleSelectNoSortingGroup();
                                                            }}
                                                            options={noSortingGroup}
                                                        />
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-default" className="form-label text-muted">Market</Label>
                                                        <Select
                                                            value={selectedSingle}
                                                            onChange={() => {
                                                                handleSelectSingle();
                                                            }}
                                                            options={SingleOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-groups" className="form-label text-muted">Code Group</Label>
                                                        <Select
                                                            value={selectedGroup}
                                                            // searchable
                                                            onChange={() => {
                                                                handleSelectGroups();
                                                            }}
                                                            options={GroupOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-no-search" className="form-label text-muted">Codes</Label>
                                                        <Select
                                                            isClearable={true}
                                                            value={selectedGroup2}
                                                            onChange={() => {
                                                                handleSelectGroups2();
                                                            }}
                                                            options={GroupOptions2}
                                                        />
                                                    </div>
                                                </Col>

                                                {/* <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-no-sorting" className="form-label text-muted">Options added
                                                            via config with no sorting</Label>                                                        
                                                        <Select
                                                            value={selectedNoSortingGroup}
                                                            onChange={() => {
                                                                handleSelectNoSortingGroup();
                                                            }}
                                                            options={noSortingGroup}
                                                        />
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </div>
                                        {/* 
                                        <div className="mt-4">
                                            <h5 className="fs-14 mb-3">Multiple select input</h5>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-multiple-default" className="form-label text-muted">Default</Label>                                                        
                                                        <Select
                                                            value={selectedMulti}
                                                            isMulti={true}                                                            
                                                            onChange={() => {
                                                                handleMulti();
                                                            }}
                                                            options={SingleOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-multiple-remove-button" className="form-label text-muted">With
                                                            remove button</Label>                                                        
                                                        <Select
                                                            value={selectedMulti2}
                                                            isMulti={true}
                                                            isClearable={true}
                                                            onChange={() => {
                                                                handleMulti2();
                                                            }}
                                                            options={SingleOptions}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-multiple-groups" className="form-label text-muted">Option
                                                            groups</Label>                                                      
                                                        <Select
                                                            value={selectedMulti3}
                                                            isMulti={true}
                                                            onChange={() => {
                                                                handleMulti3();
                                                            }}
                                                            options={GroupOptions}
                                                            closeMenuOnSelect={false}
                                                            components={animatedComponents}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div> */}
                                        {/* <div className="mt-4"> */}
                                            {/* <h5 className="fs-14 mb-3">Text inputs</h5>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-text-remove-button" className="form-label text-muted">Set limit
                                                            values with remove button</Label>                                                        
                                                        <Input className="form-control" onFocus={handleFocus}
                                                            defaultValue="Task-1" />
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-text-unique-values" className="form-label text-muted">Unique values
                                                            only, no pasting</Label>                                                       
                                                        <Input className="form-control" id="choices-text-unique-values" data-choices data-choices-text-unique-true type="text"
                                                            defaultValue="Project-A, Project-B" />
                                                    </div>
                                                </Col>
                                            </Row> */}
                                            {/* <div>
                                                <Label htmlFor="choices-text-disabled" className="form-label text-muted">Disabled</Label>                                                
                                                <Select id={'dropdown'}
                                                    options={options}
                                                    isOptionDisabled={(option) => option.disabled}>
                                                </Select>
                                            </div> */}
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="d-grid gap-2">
                                        <Button color="primary"  onClick={() => setShowTableList(true)}>Search View Code List </Button>
                                    </div> */}
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => setShowTableList(true)}>Search View Code List</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {showTableList && <Row>
                        <Col xl={12}>
                            <Card>
                                {/* <PreviewCardHeader title="View Code Lists" value ={true} /> */}
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">{codeListLable} Code Lists</h4>
                                    <div className="flex-shrink-0">
                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                {/* <Label className="form-label text-muted">Show Code</Label> */}
                                                {/* <Input className="form-check-input code-switcher" type="checkbox" /> */}
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                   {editTableLists ? <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => {handleCodeListEdit()}} >Edit</Button> : <Button className="btn btn-primary btn-custom-blue" type="button" onClick={() => {handleCodeListSubmit()}} >Submit</Button>}
                                                </div>
                                            </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">Code Group</th>
                                                        <th scope="col">Code</th>
                                                        <th scope="col">Code Description</th>
                                                        <th scope="col">Remarks</th>
                                                        <th scope="col" colSpan="3">C400.201-1823 R / 4x2 WB 4200</th>
                                                        <th scope="col" colSpan="3">C400.201-1823 R / 4x2 WB 5000</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col"></th> {/* Empty header cell for alignment */}
                                                        <th scope="col">Middle East</th>
                                                        <th scope="col">Algeria</th>
                                                        <th scope="col">Vietnam</th>
                                                        <th scope="col">Albania</th>
                                                        <th scope="col">Australia</th>
                                                        <th scope="col">Brazil</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Instrument cluster</td>
                                                        <td>EG9</td>
                                                        <td>Music System (Radio with USB connection)</td>
                                                        <td>Regularization : Code regularized as S F04 inline with release</td>
                                                        {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }}      
                                                                    // value={"SE 3"} 
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                            {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }}      
                                                                    // value={"SE 3"} 
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Instrument cluster</td>
                                                        <td>UJ3</td>
                                                        <td>Music system provision</td>
                                                        <td>Regularization : GCC Rigid</td>
                                                        {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }} 
                                                                    // value={"SE 3"}
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                            {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }}      
                                                                    // value={"SE 3"} 
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Seats</td>
                                                        <td>SM3</td>
                                                        <td>1 hinged berth at the bottom, with storage under berth</td>
                                                        <td>Regularization : Code regularized as S F04 inline with release</td>
                                                        {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }} 
                                                                    // value={"SE 3"}
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                            {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }}      
                                                                    // value={"SE 3"} 
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>Fuel Tank / Exhaust</td>
                                                        <td>VX6</td>
                                                        <td>Shorter Tail pipe</td>
                                                        <td>Regularization : GCC Rigid models - Shorter tail pipe required only for Euro 3. Code regularization done </td>
                                                        {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }} 
                                                                    // value={"SE 3"}
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                            {editTableLists ?
                                                            <>
                                                                <td>SE 3</td>
                                                                <td>S</td>
                                                                <td>S</td>
                                                            </> :
                                                            <>
                                                                <td><Input
                                                                    id="rr"
                                                                    name="rr"
                                                                    className="form-control"
                                                                    type='text'
                                                                    style={{ height: '30px', width: '80px' }}      
                                                                    // value={"SE 3"} 
                                                                    //    sx={{padding:"5px"}}
                                                                    //    onChange={handleChange}
                                                                    //    value={qGInfo?.percentage}
                                                                    required
                                                                    autoComplete='off'
                                                                //    onBlur={(event) => {
                                                                //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                //    }}
                                                                />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        id="rr"
                                                                        name="rr"
                                                                        className="form-control"
                                                                        type='text'
                                                                        style={{ height: '30px', width: '80px' }} 
                                                                        // value={"SE 3"}
                                                                        //    sx={{padding:"5px"}}
                                                                        //    onChange={handleChange}
                                                                        //    value={qGInfo?.percentage}
                                                                        required
                                                                        autoComplete='off'
                                                                    //    onBlur={(event) => {
                                                                    //        onTotalQGHoursCalculate(costIndex, qgIndex)
                                                                    //    }}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                                {/* <Striped /> */}
                                            </code>
                                        </pre>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default CodeList;