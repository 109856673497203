// Import the action types
import { CodeConfigurationActionTypes } from "./actionType";

// Define the action creators
export const getModelAction = (data) => (
    {
    type: CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST,
    data
});

export const getModelSuccess = (data) => {
    console.log('Dispatching success action with data:', data);
    return {
        type: CodeConfigurationActionTypes.GET_INPUT_MODEL_SUCCESS,
        data
    };
};

export const getModelFailure = (error) => ({
    type: CodeConfigurationActionTypes.GET_INPUT_MODEL_FAILED,
    error
});


