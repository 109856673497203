import { takeEvery, call, put } from "redux-saga/effects";
import { baseAPI } from "../../helpers/api_helper.js"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getModelSuccess,getModelFailure  } from "./action";
import { CodeConfigurationActionTypes } from "./actionType";
import { ConfigAPI } from "../../helpers/url_helper.js";

// Get All the budget by project id
console.log(baseAPI,'baseAPI')
function* workerGetModuleDetails(action) {
  const request = action.data;
  try {
    const uri = ConfigAPI.GET_INPUT_MODELS_DATA_URL;
    const { data } = yield call(baseAPI.get, `${uri}`);
    console.log(data, "acasdcadvadsvcavadv");
    if (data.httpStatusCode === 200) {
      toast.success(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getModelSuccess({
        payload: data,
        data: data.data,
      }))
      
    } else {
      toast.warn(data.httpStatusMessage, { position: 'bottom-right' });
      yield put(getModelFailure(data))
    }
  } catch (error) {
    yield put(getModelFailure(error))
  }
}

function* CodeConfigurationSaga() {
  yield takeEvery(CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST, workerGetModuleDetails);
}

export default CodeConfigurationSaga;