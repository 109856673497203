import { CodeConfigurationActionTypes } from "./actionType";


const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    modelResponse: {},

}

const CodeConfigurationModelReducer = (state = initialState, action) => {
    const response = action.data;
    console.log(response, "responsessssssss");
    switch (action.type) {
        case CodeConfigurationActionTypes.INIT_GET_BUDGET_DETAILS_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], modelResponse: {},
            }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST:
            return { ...state, loading: true, modelResponse: {} }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_SUCCESS:
            console.log(response,'red')
            return { ...state, loading: false, modelResponse: response.payload }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_FAILED:
            return { ...state, loading: false, errors: action.error }
        default: {
            return state;
        }
    }
}

export default CodeConfigurationModelReducer;