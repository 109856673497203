import React, { useEffect, useState } from 'react';
import {
    Input, Button, ButtonGroup, UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, CardBody,
    Col, Container, Row, CardHeader, Label, Card, UncontrolledDropdown
} from 'reactstrap';
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getModelAction } from '../../store/actions';


const CodeConfiguration = (props) => {

    document.title = "Code Cofiguration";

    const { modelResponse, loading } = props;
    const errors = {}
    const [formValidation, setFormValiodation] = useState({});
    console.log(modelResponse.dataList, "modelResponse");
    const animatedComponents = makeAnimated();
    const [selectedModels, setSelectedModels] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [models, setModels] = useState([]);
    const [fileName, setFileName] = useState('');

    const Models = modelResponse?.dataList?.map((item) => (
        {
          label: item.value,
          value: item.value,
          id: item.id,
          isSelected:item.isSelected
        }
      )
      ) || [];

    const ModelOption = [
        {
            label: "UK",
            options: [
                { label: "Country 1", value: "Country 1" },
                { label: "Country 2", value: "Country 2" }
            ]
        }
    ]

    function handleModels(selectedModels) {
        console.log(selectedModels, "kiughiuy");
        setSelectedModels(selectedModels);
    }

    function handleCountry(selectedCountry) {
        setSelectedCountry(selectedCountry);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          setFileName(file.name); // Store the file name (or use other file properties as needed)
        }
      };

      const handleMapFun = () => {
        if (!selectedModels) {
            errors.models = 'Please select the models'
        }
        if (!selectedCountry) {
            errors.country = 'Please select the country'
        }
        if (!fileName) {
            errors.file = 'Please Insert Code BOM'
        }
        setFormValiodation(errors);
      }

      useEffect(() => {
        console.log("gatvbclsdivgsfdilvsdlihgu");
        props.getModelAction();
      },[])


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code Configuration" pageTitle="Code Configuration" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader><h4 className="card-title mb-0">Code Configuration</h4></CardHeader>

                                <CardBody>
                                    <div>
                                        <div>
                                            <Row>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-default" className="form-label text-muted">Models</Label>
                                                        <Select
                                                            value={selectedModels}
                                                            isMulti={true}
                                                            onChange={(e) => {handleModels(e);
                                                            }}
                                                            options={Models}
                                                        />
                                                    <span className='text-validation'>{formValidation.models}</span>
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-groups" className="form-label text-muted">Country</Label>
                                                        <Select
                                                            value={selectedCountry}
                                                            isMulti={true}
                                                            // searchable
                                                            onChange={(e) => {handleCountry(e);
                                                            }}
                                                            options={ModelOption}
                                                        />
                                                        <span className='text-validation'>{formValidation.country}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={4} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formFile" className="form-label text-muted">
                                                            Insert Code BOM
                                                        </Label>
                                                        <div>
                                                            <Input
                                                                className="form-control"
                                                                type="file"
                                                                id="formFile"
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                        {/* {fileName && <span className='text-validation'>Selected file: {fileName}</span>} */}
                                                        <span className='text-validation'>{formValidation.file}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={handleMapFun} >Map</Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Code Configuration</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            <ButtonGroup>
                                                <UncontrolledButtonDropdown>
                                                    <Button className="btn-custom-blue">Excel Export</Button>
                                                    <DropdownToggle tag="button" className="btn btn-custom-blue" split>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Update Code List</DropdownItem>
                                                        <DropdownItem>Update Code Bom</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" className='tablehight' rowSpan={2}>Code Group</th>
                                                        <th scope="col" className='tablehight' rowSpan={2}>Code</th>
                                                        <th scope="col" className='tablehight' rowSpan={2}>Code Description</th>
                                                        <th scope="col" className='tablehight' rowSpan={2}>Remarks</th>
                                                        <th scope="col" colSpan="3">C400.201-1823 R / 4x2 WB 4200</th>
                                                        <th scope="col" colSpan="3">Code BOM</th>
                                                        <th scope="col" className='tablehight' rowSpan="2">Result</th>
                                                        <th scope="col" className='tablehight' rowSpan="2">Remark</th>
                                                        <th scope="col" className='tablehight' rowSpan="2">User Input</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Middle East</th>
                                                        <th scope="col">Algeria</th>
                                                        <th scope="col">Vietnam</th>
                                                        <th scope="col">Code Rule</th>
                                                        <th scope="col">CG</th>
                                                        <th scope="col">Standard Code Rule</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Accessories</td>
                                                        <td>Y10</td>
                                                        <td>First aid kit</td>
                                                        <td>Running change : I220456901, first aid kit removal for MB models(CBU/CKD)</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                        <td>873L/607L/882L</td>
                                                        <td>CG</td>
                                                        <td>873L/607/882L</td>
                                                        <td>OK</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Accessories</td>
                                                        <td>Y40</td>
                                                        <td>Hazard warning triangle</td>
                                                        <td>Regularization : GCC Rigid</td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                        <td></td>
                                                        <td>873L/607L/882L</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>NOK</td>
                                                        <td>All are optional</td>
                                                        <td className="center-content">
                                                            <ButtonGroup>
                                                                <UncontrolledButtonDropdown>
                                                                    {/* <Button color="info btn">Excel Export</Button> */}
                                                                    <DropdownToggle tag="button" className="btn btn-light" split>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu >
                                                                        <DropdownItem>Update Code List</DropdownItem>
                                                                        <DropdownItem>Update Code Bom</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Accessories</td>
                                                        <td>Y43</td>
                                                        <td>Hydraulic jack, 12t/20t</td>
                                                        <td>Regularization : Code regularized as S F04 inline with release</td>
                                                        <td>S</td>
                                                        <td></td>
                                                        <td>S</td>
                                                        <td>873L/607L/882L</td>
                                                        <td>CG</td>
                                                        <td>873L/607</td>
                                                        <td>NOK</td>
                                                        <td>Vietnam missing</td>
                                                        <td className="center-content">
                                                            <ButtonGroup>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle tag="button" className="btn btn-light" split>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu >
                                                                        <DropdownItem>Update Code List</DropdownItem>
                                                                        <DropdownItem>Update Code Bom</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Accessories</td>
                                                        <td>XX1</td>
                                                        <td>Shorter Tail pipe</td>
                                                        <td>Provision for Fire extinguisher, 2 kg</td>
                                                        <td></td>
                                                        <td>S</td>
                                                        <td>S</td>
                                                        <td>873L/607L/882L</td>
                                                        <td>CG</td>
                                                        <td></td>
                                                        <td>NOK</td>
                                                        <td>Algeria additional</td>
                                                        <td className="center-content">
                                                            <ButtonGroup>
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle tag="button" className="btn btn-light" split>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu >
                                                                        <DropdownItem>Update Code List</DropdownItem>
                                                                        <DropdownItem>Update Code Bom</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                                {/* <Striped /> */}
                                            </code>
                                        </pre>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = (state) => ({
    modelResponse: state.CodeConfigurationModelReducer.modelResponse,
    loading: state.CodeConfigurationModelReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
    getModelAction: (data) => {
        dispatch(getModelAction(data))
    },

})

export default connect(mapStateToProps, mapDispatchToProps)(CodeConfiguration);
