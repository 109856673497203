import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from '../../Components/Common/PreviewCardHeader';
// import { StripedColumnsTables } from './BasicTablesCode';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import DeleteModal from '../DeleteModel/DeleteModel';



const VehicleGroups = () => {
    document.title = "CRM | EVT-Vehicle Group";

    const ModelNameOptions = [
        { value: '18 T R', label: '18 T R' },
        { value: '28T R', label: '28T R' }
    ];


    const [selectedGroup2, setSelectedGroup2] = useState(null);
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);

    const handleEdit = () => {
        setOpenModel(true);
    }

    const handleAdd = () => {
        setOpenModel(true);
    }


    const handleSave = () => {
        setOpenModel(false);
    }

    function handleSelectGroups2(selectedGroup2) {
        setSelectedGroup2(selectedGroup2);
    }

    const handleDeleteTodo = () => {
        // if (todo) {
            // dispatch(onDeleteTodo(todo.id));
            setDeleteModal(false);
        // }
    };

    const onClickTodoDelete = (todo) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title="Vehicle Group" pageTitle="Masters" />
                    {openModel && 
                    <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Vehicle Group</h4> </CardHeader>
                            <CardBody>
                                <div>
                                    <div>
                                    <Row>
                                            <Col lg={4} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Type</Label>
                                                    <Select
                                                        isClearable={true}
                                                        value={selectedGroup2}
                                                        onChange={() => {
                                                            handleSelectGroups2();
                                                        }}
                                                        options={ModelNameOptions}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <div className="mb-3">
                                                <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group</Label>
                                                <Input type="text" className="form-control" id="placeholderInput" placeholder="Add Vehicle Group" />
                                                </div>
                                            </Col>

                                            <Col lg={4} md={6}>
                                                <div className="pt-4">
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                                        <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light'>Clear</Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </CardBody>
                            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' >Save</Button>
                                <Button className="btn btn-primary me-3 mb-2 btn-custom-blue" type="button" color='light'>Clear</Button>
                            </div> */}
                        </Card>
                    </Col>
                </Row>
                }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                {/* <PreviewCardHeader title="View Code Lists" value ={true} /> */}
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Vehicle Group List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            {/* <Label className="form-label text-muted">Show Code</Label> */}
                                            {/* <Input className="form-check-input code-switcher" type="checkbox" /> */}
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>
                                            </div>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                    <Row className="g-4 mb-4">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No <i className=" bx bx-sort me-3" style={{color:"#182b45"}} ></i></th>
                                                        <th scope="col">Vehicle Type <i className=" bx bx-sort me-3" style={{color:"#182b45"}} ></i></th>
                                                        <th scope="col">Vehicle Group <i className=" bx bx-sort me-3" style={{color:"#182b45"}} ></i></th>
                                                        <th scope="col"  style={{width: 50}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>7675L</td>
                                                        <td>Afghanistan</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>  */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>5475L</td>
                                                        <td>Afghanistan</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>   */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>7875L</td>
                                                        <td>Malawi</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>   */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>7655L</td>
                                                        <td>Bangladesh</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>   */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>9855L</td>
                                                        <td>Benin (Dahomey)</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>   */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                    <tr>
                                                        <td>6</td>
                                                        <td>6545L</td>
                                                        <td>North German Confederation*</td>
                                                        {/* <td><i className=" ri-pencil-line me-3" style={{color:"#182b45"}} onClick={() => handleEdit()}></i><i className="ri-delete-bin-6-line" style={{color:"red"}}></i></td>   */}
                                                        <td>
                                                            <div className="hstack gap-2">
                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit()}>
                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                </button>
                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete()}>
                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                </button>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                                {/* <Striped /> */}
                                            </code>
                                        </pre>
                                    </div>
                                    <Row className="g-4 mt-3">
                                        <Col className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <Pagination count={10} shape="rounded" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default VehicleGroups;